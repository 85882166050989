import React, {useEffect, useState} from "react";
import {REMOVE_DROP_SHADOW} from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {LOCALIZATION} from "../../Material-TableCustomization/Localization/LocalizationES";
import {PAYMENTS_SUMMARY_COLUMN_DEFINITION_PROPS} from "../../Material-TableCustomization/ColumnsDefinition/PaymentsSummaryColumnDefinition";
import ModalNewOutcomePayment from "../../Modals/ModalNewOutcomePayment";
import {red} from "@material-ui/core/colors";
import MaterialTable, {MTableToolbar} from "material-table";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from "@material-ui/core/Button";
import * as XLSX from "xlsx";

export default function PaymentsSummaryTable(props) {
    const [dayPayments, setDayPayments] = useState([]);
    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
        props.updateDate(`summary?date=${date.toISOString().substring(0, 10)}`);
    };

    function onOutcomePaymentCreated(outcome) {
        console.log(outcome);
        const temp = [outcome].concat(dayPayments);
        setDayPayments(temp);
    }

    useEffect(() => {
        setDayPayments(props.dayPayments);
    }, [props.dayPayments]);

    function getTodaysDate() {
        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date().toLocaleDateString('en-EN', options);
    }

    function monthNumberToString(monthNumber) {
        if (monthNumber > 11 || monthNumber < 0) {
            return "Invalid month number";
        }

        const months = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ];
        return months[monthNumber];
    }

    function handleOnExport() {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dayPayments.map(o => ({
            fecha: o.friendlyDate, metodoPago: o.paymentType, nombre: o.name, recibido: o.moneyTotal, concept: o.concept,
            mes: monthNumberToString(o.monthConcept), año: o.yearConcept, referencia: o.reference, grado: o.major
        })));
        XLSX.utils.book_append_sheet(wb, ws, "Pagos");
        XLSX.writeFile(wb, `${getTodaysDate()}.xlsx`);
    }


    function renderPaymentsSummary() {
        return (
            <div>
                <MaterialTable
                    columns={PAYMENTS_SUMMARY_COLUMN_DEFINITION_PROPS(props.history)}
                    data={dayPayments}
                    title="Pagos"
                    isLoading={props.isLoading}
                    options={{
                        pageSize: 20,
                        addRowPosition: 'first',
                        filtering: true,
                        rowStyle: rowData => {
                            return {
                                backgroundColor: rowData.concept === "EGRESO_CORTE" || rowData.concept === "EGRESO_GASTO"
                                    ? red[100]
                                    : "transparent"
                            };
                        }
                    }}
                    components={{
                        ...REMOVE_DROP_SHADOW,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <div style={{padding: '0px 10px'}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Fecha de Pago"
                                            format="MM-dd-yyyy"
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'Seleccione Fecha',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div>
                                    <Button variant="contained" color="primary" onClick={handleOnExport}>
                                        Exportar a Excel
                                    </Button>
                                </div>
                            </div>
                        ),
                    }}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('pago', 'pagos')}
                />
                <ModalNewOutcomePayment
                    setIsLoading={props.setIsLoading}
                    onOutcomePaymentCreated={onOutcomePaymentCreated}
                />
            </div>
        );
    }

    return (
        <div className="PaymentsSummary">
            {renderPaymentsSummary()}
        </div>
    );
};