import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import config from "../../../config";
import MaterialTable, {MTableToolbar} from "material-table";
import CustomizedSnackbars from "../../CommonUtilities/CustomizedSnackbars";
import {REMOVE_DROP_SHADOW} from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {LOCALIZATION} from "../../Material-TableCustomization/Localization/LocalizationES";
import {STUDENT_PAYMENTS_COLUMN_DEFINITION_PROPS} from "../../Material-TableCustomization/ColumnsDefinition/StudentsPaymentsColumnDefinition";
import {API_BASE_PATH} from "../../CommonUtilities/UtilityFunctions";
import ModalNewPayment from "../../Modals/ModalNewPayment";
// import ModalMultiplePayments from "../../Modals/ModalMultiplePayments";

export default function StudentPaymentsTable(props) {
    const [studentPayments, setStudentPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});

    const loadStudentPaymentsApi = () => {
        return API.get(API_BASE_PATH, `/tesoreria/get-student-payments/${props.studentUuid}`);
    };

    const deletePaymentApi = (paymentInfo) => {
        return API.del(API_BASE_PATH, "/tesoreria/payments", {
            body: {...paymentInfo, studentUuid: props.studentUuid}
        });
    };

    const updatePaymentApi = (paymentInfo) => {
        return API.put(API_BASE_PATH, "/tesoreria/payments", {
            body: {...paymentInfo, studentUuid: props.studentUuid}
        });
    };

    const loadStudentPayments = async () => {
        setIsLoading(true);
        try {
            const studentPayments = await loadStudentPaymentsApi();
            setStudentPayments(studentPayments);
        } catch (e) {
            alert("No se pudo actualizar la lista de estudiantes. Por favor intente de nuevo.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeOnAlert = shouldPresentAlert => {
        setAlertStatus(shouldPresentAlert);
    }

    useEffect(() => {
        loadStudentPayments();
    }, [props.studentUuid]);

    async function handleOnRowUpdate(newData, oldData, resolve, reject) {
        try {
            await updatePaymentApi(newData);
            const index = studentPayments.findIndex(o => o.createdAt === oldData.createdAt);
            setStudentPayments([
                ...studentPayments.slice(0, index),
                newData,
                ...studentPayments.slice(index + 1)
            ]);
            resolve();
        } catch (e) {
            console.log(e);
            alert("No se pudo actualizar el pago, por favor intente de nuevo");
            reject();
        } finally {
            resolve();
        }
    }

    async function onPaymentAdded(newPayment, isMultiple = false) {
        if (isMultiple) {
            setStudentPayments([
                ...newPayment.reverse(), ...studentPayments
            ]);
        } else {
            setStudentPayments([
                newPayment, ...studentPayments
            ]);
        }

    }

    // async function handleOnRowDelete(oldData, resolve, reject) {
    //     try {
    //         await deletePaymentApi(oldData);
    //         const index = studentPayments.findIndex(o => o.createdAt === oldData.createdAt);
    //         setStudentPayments([
    //             ...studentPayments.slice(0, index),
    //             ...studentPayments.slice(index + 1)
    //         ]);
    //         resolve();
    //     } catch (e) {
    //         alert("No se pudo borrar el pago. Por favor intente de nuevo");
    //         reject();
    //     }
    // }

    function handlePrint(event, rowData) {
        const url = `${config.apiGateway.URL}/tesoreria/pdf/payment/${rowData.studentUuid}?createdAt=${rowData.createdAt}`;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    }

    function renderStudentPayments() {
        return (
            <div>
                <MaterialTable
                    columns={STUDENT_PAYMENTS_COLUMN_DEFINITION_PROPS(props)}
                    data={studentPayments}
                    title="Pagos"
                    isLoading={isLoading}
                    options={{
                        pageSize: 10,
                        addRowPosition: 'first',
                        filtering: true,
                    }}
                    components={{
                        ...REMOVE_DROP_SHADOW,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <div style={{padding: '0px 10px'}}>
                                    {/*<ModalMultiplePayments*/}
                                    {/*    setIsLoading={setIsLoading}*/}
                                    {/*    student={props.student}*/}
                                    {/*    onPaymentAdded={onPaymentAdded}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        ),
                    }}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('pago', 'pagos')}
                    editable={{
                        // onRowAdd: (newData) => new Promise((resolve, reject) => {
                        //     handleOnRowAdd(newData, resolve, reject);
                        // }),
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            handleOnRowUpdate(newData, oldData, resolve, reject);
                        }),
                        // onRowDelete: (oldData) => new Promise((resolve, reject) => {
                        //     handleOnRowDelete(oldData, resolve, reject);
                        // }),
                    }}
                    actions={[
                        {
                            icon: 'printer',
                            tooltip: 'Imprimir Comprobante',
                            onClick: (event, rowData) => handlePrint(event, rowData)
                            // onClick: (event, rowData) => alert("You saved " + rowData.name)
                        }
                    ]}
                />
                <ModalNewPayment
                    student={props.student}
                    grades={props.grades}
                    setIsLoading={setIsLoading}
                    onPaymentAdded={onPaymentAdded}
                />
            </div>
        );
    }

    return (
        <div className="Professors">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            {renderStudentPayments()}
        </div>
    );
};
