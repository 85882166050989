export const calculateCurrentCuatrimestreYYYYMMDD = () => {
    return `${new Date().getFullYear()}-0${4 * (Math.ceil(parseFloat((new Date().getMonth() + 1) / 4)) - 1) + 1}-01`;
}

export const calculateCurrentCuatrimestreYYYYCC = () => {
    return `${new Date().getFullYear()}-0${4 * (Math.ceil(parseFloat((new Date().getMonth() + 1) / 4)) - 1) + 1}`;
}

export const K_ALERT = {
    SEVERITY: {
        SUCCESS: 'success',
        ERROR: 'error'
    },
    MESSAGE: {
        ERROR: 'Por favor intente de nuevo',
        GROUPS: {
            GROUP_DELETE: "El grupo ha sido dado de baja del sistema exitosamente",
            GROUP_UPDATE: "Los datos del grupo han sido actualizado exitosamente",
            GROUP_CREATE: "El grupo ha sido dado de creado exitosamente",
            GROUP_ADD_USER_TO_GROUP: "El estudiante ha sido añadido al grupo exitosamente",
            GROUP_DELETE_USER_FROM_GROUP: "El estudiante ha sido removido al grupo exitosamente",
        },
        STUDENTS: {
            STUDENT_DELETE: "El estudiante ha sido dado de baja del sistema exitosamente",
            STUDENT_UPDATE: "Los datos del estudiante han sido actualizado exitosamente",
            STUDENT_CREATE: "El estudiante ha sido dado de alta exitosamente"
        },
        PROFESSORS: {
            PROFESSOR_DELETE: "El profesor ha sido dado de baja del sistema exitosamente",
            PROFESSOR_UPDATE: "Los datos del profesor han sido actualizado exitosamente",
            PROFESSOR_CREATE: "El profesor ha sido dado de alta exitosamente"
        },
        TOPICS: {
            TOPICS_UPDATE_PROFESSOR: "El profesor ha sido vinculado exitosamente",
            TOPICS_UPDATE_SCHEDULE: "El horario ha sido actualizado exitosamente",
        },
        GRADES: {
            GRADES_UPDATED: "Las calificaciones han sido guardades exitosamente",
        }
    }
};

export const openAlert = (alertFunction, message = K_ALERT.MESSAGE.ERROR, severity = K_ALERT.SEVERITY.ERROR) => {
    alertFunction({
        open: true,
        message: message,
        severity: severity
    });
};

export const getListOfCyclesWithObjects = (lookupObject = false) => {
    const currentYear = new Date().getFullYear();
    const currentYearCycle = Math.ceil(parseFloat((new Date().getMonth()) / 4));
    let cyclesObject = [];
    for (let i = -2; i <= 2; i++) {
        const cycle = (currentYearCycle + i) % 3
        const yearGap = currentYearCycle + i <= 0 ? -1 : (currentYearCycle + i) === 3 ? 0 : parseInt((currentYearCycle + i) / 3);
        let cycleForObject = `${currentYear + yearGap}-0${cycle === 0 ? 3 : cycle}`
        cyclesObject[cycleForObject] = (cycleForObject === `${currentYear}-0${currentYearCycle}`);
    }
    return lookupObject ? Object.keys(cyclesObject).map(cycle => {return {[cycle]: cycle}}) : cyclesObject;
}

/**
 *
 * @returns "2020-02" YYYY-0X, where X is the cycle
 */
export const getCurrentCycle = () => {
    return `${new Date().getFullYear()}-0${Math.ceil(parseFloat(new Date().getMonth() / 4))}`;
};


export const majors = () => {
    return {
        "AMBIENTAL": "Ambiental",
        "INDUSTRIAL": "Industrial",
        "PETROLERA": "Petrolero",
        "ADMINISTRACION": "Administración",
        "DERECHO": "Derecho",
        "PSICOPEDAGOGIA": "Psicopedagogía",
        "MAESTRIA EN EDUCACION": "Maestría en Educación",
        "MAESTRIA PETROLERA": "Maestría Petrolera",
        "ENFERMERIA": "Enfermería",
    };
};

export const MAJORS_UVT = {
    "AMBIENTAL": "Ambiental",
    "INDUSTRIAL": "Industrial",
    "PETROLERA": "Petrolero",
    "ADMINISTRACION": "Administración",
    "DERECHO": "Derecho",
    "PSICOPEDAGOGIA": "Psicopedagogía",
    "MAESTRIA EN EDUCACION": "Maestría en Educación",
    "MAESTRIA PETROLERA": "Maestría Petrolera",
    "ENFERMERIA": "Enfermería",
};

export const PAYMENTS_MONEY_TYPES = {
    "EFECTIVO": "EFECTIVO",
    "BANCO_DEBITO": "BANCO_DEBITO",
    "BANCO_CREDITO": "BANCO_CREDITO",
    "BANCO_CREDITO_MSI": "BANCO_CREDITO_MSI",
    "DEPOSITO": "DEPOSITO",
    "TRANSFERENCIA": "TRANSFERENCIA",
}

export const API_BASE_PATH = "misServiciosEscolares";

export const PAYMENTS_CONCEPTS = {
    "EGRESO": "EGRESO",
    "EGRESO_GASTO": "EGRESO GASTO",
    "EGRESO_CORTE": "EGRESO CORTE",
    "CEDAR_UNIFORMES": "CEDAR_UNIFORMES",
    "CEDAR_TINES": "CEDAR_TINES",
    "CEDAR_LIBRETA": "CEDAR_LIBRETA",
    "CEDAR_INTERSEMESTRAL": "CEDAR_INTERSEMESTRAL",
    "CEDAR_INTRASEMESTRAL": "CEDAR_INTRASEMESTRAL",
    "CEDAR_REINSCRIPCION": "CEDAR_REINSCRIPCION",
    "CEDAR_BAJA_DEFINITIVA": "CEDAR_BAJA_DEFINITIVA",
    "CEDAR_CONSTANCIA_PARCIAL": "CEDAR_CONSTANCIA_PARCIAL",
    "CEDAR_CONSTANCIA_SIMPLE": "CEDAR_CONSTANCIA_SIMPLE",
    "CEDAR_CONSTANCIA_PASAPORTE": "CEDAR_CONSTANCIA_PASAPORTE",
    "CEDAR_CONSTANCIA_CALIFICACIONES": "CEDAR_CONSTANCIA_CALIFICACIONES",
    "CEDAR_SECU_LIBROS": "CEDAR_SECU_LIBROS",
    "CEDAR_PREPA_LIBROS": "CEDAR_PREPA_LIBROS",
    "CEDAR_SECU_INSCRIPCION": "CEDAR_SECU_INSCRIPCION",
    "CEDAR_PREPA_INSCRIPCION": "CEDAR_PREPA_INSCRIPCION",
    "CEDAR_SECU_REINSCRIPCION": "CEDAR_SECU_REINSCRIPCION",
    "CEDAR_PREPA_REINSCRIPCION": "CEDAR_PREPA_REINSCRIPCION",
    "CEDAR_SECU_CUOTA_ANUAL": "CEDAR_SECU_CUOTA_ANUAL",
    "CEDAR_PREPA_CUOTRA_ANUAL": "CEDAR_PREPA_CUOTRA_ANUAL",
    "CEDAR_SECU_COLEGIATURA_10": "CEDAR_SECU_COLEGIATURA_10",
    "CEDAR_PREPA_COLEGIATURA_10": "CEDAR_PREPA_COLEGIATURA_10",
    "CEDAR_SECU_COLEGIATURA_12": "CEDAR_SECU_COLEGIATURA_12",
    "CEDAR_PREPA_COLEGIATURA_12": "CEDAR_PREPA_COLEGIATURA_12",
    "CEDAR_LIBROS": "CEDAR LIBROS",
    "CEDAR_INSCRIPCION": "CEDAR INSCRIPCION",
    "CEDAR_CUOTA_ANUAL": "CEDAR CUOTA ANUAL",
    "UVT_COLEGIATURA": "UVT COLEGIATURA",
    "UVT_INSCRIPCION": "UVT INSCRIPCION",
    "UVT_REINSCRIPCION": "UVT REINSCIPCION",
    "UVT_EXAM_ORDINARIO": "UVT EXAMEN ORDINARIO",
    "UVT_EXTRAORDINARIO": "UVT EXTRAORDINARIO",
    "UVT_CONSTANCIA_SIMPLE": "UVT CONSTANCIA SIMPLE",
    "UVT_CONSTANCIA_CALIFICACIONES": "UVT CONSTANCIA_CALIFICACIONES",
    "UVT_TERMINO_ESTUDIOS": "UVT TERMINO ESTUDIOS",
    "UVT_CERTIFICADO_PARCIAL": "UVT CERTIFICADO PARCIAL",
    "UVT_CERTIFICADO_PARCIAL_IMPO": "UVT CERTIFICADO PARCIAL IMPO",
    "UVT_KARDEX": "UVT KARDEX",
    "UVT_RECURSAMIENTO": "UVT RECURSAMIENTO",
    "UVT_EXAM_RECUPERACION": "UVT EXAM RECUPERACION",
    "UVT_REPOS_TITULO": "UVT REPOS_TITULO",
    "UVT_EQUIVALENCIA": "UVT EQUIVALENCIA",
    "UVT_BAJA_DEFINITIVA": "UVT BAJA DEFINITIVA",
    "UVT_MATERIA_EQUIPARADA": "UVT MATERIA EQUIPARADA",
    "UVT_PASE_VEHICULAR": "UVT PASE VEHICULAR",
    "UVT_LIBRETA": "UVT LIBRETA",
    "UVT_CARPETA_3": "UVT CARPETA 3''",
    "UVT_CARPETA_5": "UVT CARPETA 5''",
    "UVT_TITULACIONAL": "UVT TITULACIONAL",
    "UVT_CEDULA": "UVT CEDULA",
    "UVT_MOD_TESIS": "UVT MOD TESIS",
    "UVT_MOD_DIPLOMADO": "UVT MOD DIPLOMADO",
    "UVT_MOD_CURSO_PROFUND": "UVT MOD CURSO PROFUND",
    "UVT_MOD_OTRAS": "UVT_MOD OTRAS",
    "UVT_INGLES": "UVT INGLES",
    "UVT_INGLES_EXTRA": "UVT INGLES EXTRA",
    "UVT_INGLES_COLOCACION": "UVT INGLES_COLOCACION",
    "UVT_ENF_INSCRIPCION": "UVT ENF INSCRIPCION",
    "UVT_ENF_REINSCRIPCION": "UVT_ENF_REINSCRIPCION",
    "UVT_ENF_EXTRAORDINARIO": "UVT ENF EXTRAORDINARIO",
    "UVT_ENF_COLEGIATURA": "UVT ENF COLEGIATURA",
    "UVT_ENF_NIVELACION": "UVT ENF NIVELACION",
    "UVT_ENF_PRACTICAS": "UNVT ENF PRACTICAS",
    "OTRO": "OTRO",
    "UVT_MAESTRIA_COLEGIATURA": " UVT MAESTRIA COLE",
    "UVT_MAESTRIA_INSCRIPCION": "UVT MAESTRIA INSCRIPCION",
    "UVT_MAESTRIA_REINSCRIPCION": "UVT MAESTRIA REINSCRIPCION",
    "UVT_ENF_SEGURO": "UVT_ENF_SEGURO"
};

export const PAYMENTS_CONCEPTS_PRICES = {
    "CEDAR_LIBROS": 3000,
    "CEDAR_UNIFORMES": 300,
    "CEDAR_TINES": 60,
    "CEDAR_LIBRETA": 50,
    "CEDAR_INTERSEMESTRAL": 550,
    "CEDAR_INTRASEMESTRAL": 550,
    "CEDAR_INSCRIPCION": 1800,
    "CEDAR_REINSCRIPCION": 1800,
    "CEDAR_CUOTA_ANUAL": 1800,
    "CEDAR_BAJA_DEFINITIVA": 1500,
    "CEDAR_CONSTANCIA_PARCIAL": 1500,
    "CEDAR_CONSTANCIA_SIMPLE": 50,
    "CEDAR_CONSTANCIA_PASAPORTE": 80,
    "CEDAR_CONSTANCIA_CALIFICACIONES": 100,
    "CEDAR_SECU_COLEGIATURA_10": 1800,
    "CEDAR_SECU_COLEGIATURA_12": 1500,
    "CEDAR_PREPA_COLEGIATURA_10": 2280,
    "CEDAR_PREPA_COLEGIATURA_12": 1900,
    "UVT_COLEGIATURA": 1400,
    "UVT_INSCRIPCION": 100,
    "UVT_REINSCRIPCION": 1400,
    "UVT_EXTRAORDINARIO": 500,
    "UVT_CONSTANCIA_SIMPLE": 120,
    "UVT_CONSTANCIA_CALIFICACIONES": 200,
    "UVT_TERMINO_ESTUDIOS": 500,
    "UVT_CERTIFICADO_PARCIAL": 1500,
    "UVT_CERTIFICADO_PARCIAL_IMPO": 2500,
    "UVT_KARDEX": 50,
    "UVT_RECURSAMIENTO": 800,
    "UVT_EXAM_RECUPERACION": 300,
    "UVT_REPOS_TITULO": 2500,
    "UVT_EQUIVALENCIA": 1500,
    "UVT_BAJA_DEFINITIVA": 1200,
    "UVT_MATERIA_EQUIPARADA": 100,
    "UVT_PASE_VEHICULAR": 50,
    "UVT_LIBRETA": 50,
    "UVT_CARPETA_3": 80,
    "UVT_CARPETA_5": 150,
    "UVT_TITULACIONAL": 16000,
    "UVT_MOD_TESIS": 18000,
    "UVT_MOD_DIPLOMADO": 16000,
    "UVT_MOD_CURSO_PROFUND": 12000,
    "UVT_MOD_OTRAS": 5000,
    "UVT_INGLES": 1200,
    "UVT_INGLES_EXTRA": 500,
    "UVT_INGLES_COLOCACION": 500,
    "UVT_ENF_INSCRIPCION": 1800,
    "UVT_ENF_REINSCRIPCION": 1800,
    "UVT_ENF_EXTRAORDINARIO": 500,
    "UVT_ENF_COLEGIATURA": 1400,
    "UVT_ENF_NIVELACION": 300,
    "UVT_ENF_PRACTICAS": 1200,
    "OTRO": 0,
};


export const PAYMENTS_DISCOUNTS = {
    "CEDAR_100": "CEDAR_100",
    "EF_380": "EF_380",
    "EF_480": "EF_480",
    "SETAB_50": "SETAB_50",
    "SITEM_10": "SITEM_10",
}

export const PAYMENTS_DISCOUNTS_VALUE = {
    "CEDAR_100": 10,
    "EF_380": 10,
    "EF_480": 10,
    "SETAB_50": 10,
    "SITEM_10": 10,
}

export const MAJORS_CEDAR = {
    "SECUNDARIA": "Secundaria",
    "PREPARATORIA": "Preparatoria",
}

export const MAJORS_MODES = {
    "AMBIENTAL": ['MIXTA'],
    "INDUSTRIAL": ['MIXTA'],
    "PETROLERA": ['MIXTA'],
    "ADMINISTRACION": ['MIXTA'],
    "DERECHO": ['MIXTA'],
    "PSICOPEDAGOGIA": ['ESCOLARIZADO'],
    "MAESTRIA EN EDUCACION": ['ESCOLARIZADO'],
    "MAESTRIA PETROLERA": ['ESCOLARIZADO'],
    "ENFERMERIA": ['ESCOLARIZADO'],
};

export const statuses = () => {
    return {
        "ACTIVO": "Activo",
        "INACTIVO": "Inactivo",
        "BAJATEMPORAL": "Baja Temporal",
        "BAJADEFINITIVA": "Baja Definitiva",
        "MOROSO": "Moroso",
        "TITULADO": "Titulado",
        "EGRESADO": "Egresado",
        "REINGRESO": "Reingreso",

    }
};

export const STATUSES = {
    "ACTIVO": "Activo",
    "INACTIVO": "Inactivo",
    "BAJATEMPORAL": "Baja Temporal",
    "BAJADEFINITIVA": "Baja Definitiva",
    "MOROSO": "Moroso",
    "TITULADO": "Titulado",
    "EGRESADO": "Egresado",
    "REINGRESO": "Reingreso",
    "DESCONOCIDO": "Desconocido",
    "ACTIVO_MOROSO": "Activo Moroso",
};

export const randomString = (strLength) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for ( let i = 0; i < strLength; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export const formatPhoneNumber = (phone) => {
    let cleaned = ('' + phone).replace(/\D/g, '')
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + ' ' + match[3]
    }
    return phone;
}

export function getTodayDateYYY_MM_DD() {
    const date = new Date();
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split('T')[0];
}

export const formatNumberCurrency = number => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formatter.format(number);
}