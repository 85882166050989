import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./containers/Containers/Home";
import Login from "./containers/Containers/Login";
import Signup from "./containers/Containers/Signup";
import AppliedRoute from "./components/AppliedRoute";
import NotFound from "./containers/Containers/NotFound";
import PaymentSummary from "./containers/Containers/PaymentSummary";
import Professors from "./containers/Containers/Professors";
import ProfessorDetails from "./containers/Containers/ProfessorDetails";
import StudentDetails from "./containers/Containers/StudentDetails";
import ResetPassword from "./containers/Containers/ResetPassword";

export default function Routes({appProps}) {
    return (
        <Switch>
            <AppliedRoute path="/" exact component={Home} appProps={appProps}/>
            <AppliedRoute path="/login" exact component={Login} appProps={appProps}/>
            <AppliedRoute path="/signup" exact component={Signup} appProps={appProps}/>
            <AppliedRoute path="/reset" exact component={ResetPassword} appProps={appProps}/>
            <AppliedRoute path="/professors" exact component={Professors} appProps={appProps}/>
            <AppliedRoute path="/summary" exact component={PaymentSummary} appProps={appProps}/>
            <AppliedRoute path="/students/:id" exact component={StudentDetails} appProps={appProps}/>
            <AppliedRoute path="/simulate-professor/:id" exact component={Home} appProps={appProps}/>
            <AppliedRoute path="/professors/:id" exact component={ProfessorDetails} appProps={appProps}/>
            <Route component={NotFound}/>
        </Switch>
    );
};
