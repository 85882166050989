import {API} from "aws-amplify";
import "../Stylesheets/StudentDetails.css";
import React, {useState, useEffect} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {API_BASE_PATH, getTodayDateYYY_MM_DD} from "../../components/CommonUtilities/UtilityFunctions";
import PaymentsSummaryDetailsTabsContainer from "../../components/Details/Payments/PaymentsSummaryDetailsTabsContainer";
import PaymentsSummaryTableMetadata from "../../components/Details/Payments/PaymentsSummaryTableMetadata";

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function PaymentSummary(props) {
    const [dayPayments, setDayPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [summaryDate, setSummaryDate] = useState(getTodayDateYYY_MM_DD());
    const [dayPaymentsMetadata, setDayPaymentsMetadata] = useState({
        profit: "",
        incomeTotal: "",
        incomeOther: "",
        incomeCash: "",
        outcomeCash: "",
        caja: "",
        titulacion: "",
        practicasEnfermeria: "",
        cedarUniformes: "",
        preparatoriaLibros: "",
        secundariaLibros: "",
        uvtSeguroEnfermeria: "",
        uvtSeguroEscolar: "",
    });
    const classes = useStyles();

    function loadDatePaymentsApi(date) {
        return API.get(API_BASE_PATH, `/tesoreria/list-summary-payments?friendlyDate=${date}`);
    }

    async function loadDatePayments(date) {
        setIsLoading(true);
        try {
            const response = await loadDatePaymentsApi(date);
            setDayPayments(response.dayPayments);
            const {
                profit,
                incomeTotal,
                incomeOther,
                incomeCash,
                outcomeCash,
                outcomeGastos,
                outcomeCortes,
                caja,
                titulacion,
                practicasEnfermeria,
                cedarUniformes,
                preparatoriaLibros,
                secundariaLibros,
                titulacionTesis,
                titulacionOtras,
                titulacionCurso,
                titulaciónDiplomado,
                uvtSeguroEnfermeria,
                uvtSeguroEscolar,
            } = response;
            setDayPaymentsMetadata({
                profit,
                incomeTotal,
                incomeOther,
                incomeCash,
                outcomeCash,
                outcomeGastos,
                outcomeCortes,
                caja,
                titulacion,
                practicasEnfermeria,
                cedarUniformes,
                preparatoriaLibros,
                secundariaLibros,
                titulacionTesis,
                titulacionOtras,
                titulacionCurso,
                titulaciónDiplomado,
                uvtSeguroEnfermeria,
                uvtSeguroEscolar,
            });
        } catch (e) {
            alert("No se pudieron obtener los pagos del día");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (new URLSearchParams(props.location.search).get("date")) {
            setSummaryDate(new URLSearchParams(props.location.search).get("date"));
        }
        loadDatePayments(new URLSearchParams(props.location.search).get("date") || getTodayDateYYY_MM_DD());
    }, [props.match.params.id, props.location.search]);

    function onOutcomeCreate(outcome) {
        console.log(outcome);
    }

    const updateDate = redirectUrl => {
        props.history.push(redirectUrl);
    }

    function renderGroup() {
        return (
            <div>
                <div className={classes.title}>
                    <PaymentsSummaryTableMetadata
                        profit={dayPaymentsMetadata?.profit}
                        incomeTotal={dayPaymentsMetadata?.incomeTotal}
                        incomeOther={dayPaymentsMetadata?.incomeOther}
                        incomeCash={dayPaymentsMetadata?.incomeCash}
                        outcomeCash={dayPaymentsMetadata?.outcomeCash}
                        caja={dayPaymentsMetadata?.caja}
                        outcomeCortes={dayPaymentsMetadata.outcomeCortes}
                        outcomeGastos={dayPaymentsMetadata.outcomeGastos}
                        summaryDate={summaryDate}
                    />
                </div>
                <div>
                    <PaymentsSummaryDetailsTabsContainer
                        dayPayments={dayPayments}
                        history={props.history}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        onOutcomeCreate={onOutcomeCreate}
                        dayPaymentsMetadata={dayPaymentsMetadata}
                        updateDate={updateDate}
                    />
                </div>

            </div>
        );
    }

    return (
        <div>
            {props.isAuthenticated ? renderGroup() : renderLander()}
        </div>
    );
};